<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid">
			<div class="col-12">
				<div class="card card-w-title">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.firmaAdi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>
		
		<div class="grid">
			<div class="col-7">
				<div class="card">
					<h5>ARAMA BİLGİLERİ</h5>

                    <div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="arama">Aranacak Bölüm</label>
                                <Dropdown id="arama" v-model="mainData.logicalName" :options="entityList" optionLabel="label" optionValue="attributeValue" placeholder="Arama" @change="changeEntityList" :filter="true" :showClear="true" />
							</div>
						</div>
                    </div>

                    <br>

					<div class="grid" v-for="(item, index) in mainData.filters" :key="item">
                        <div class="col-12">
                            <AdvancedFindAttribute :index="index" :entityName="mainData.logicalName" :objectTypeCode="mainData.objectTypeCode" :rowData="item" :attributeDataList="attributeList" @addRow="addRow()" @removeRow="removeRow($event)" @selectAttribute="selectAttribute($event)"></AdvancedFindAttribute>
                        </div>
					</div>
                    <Button v-if="mainData.logicalName != null" label="Yeni Filtre" icon="pi pi-plus" class="p-button" @click="addRow()" />
				</div>
			</div>
        </div>


        <div class="p-grid" v-if="mainData.logicalName != ''" style="height: 100%">
            <div class="p-col-12 p-md-12" style="height: 100%">
                <div class="card p-fluid" style="height: 100%">
                    <CrmDataTable v-if="mainData.logicalName != ''" :key="CrmDataTable_key" :baseEntityName="mainData.logicalName" :options="CrmDataTable_options" @onRowSelect="onRowSelect" />
                </div>
            </div>
        </div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import AdvancedFindAttribute from "./AdvancedFindAttribute.vue";

export default {
    data() {
        return {
            crmService: null,
            entityList: [
                { label: "Firma", attributeValue: "account", objectTypeCode: 1 },
                { label: "Sipariş", attributeValue: "salesorder", objectTypeCode: 1088 },
                { label: "Fırsat", attributeValue: "opportunity", objectTypeCode: 3 },
            ],
            attributeList: [],
            mainData: {
                logicalName: "",
                objectTypeCode: "",
                filters: []
            },
            nestedMenuitems: [
                {
                    label: "Bul",
                    icon: "pi pi-fw pi-play",
                    command: () => { this.OnSearch(); },
                },
            ],
            CrmDataTable_key: 0,
            CrmDataTable_options: {},
            filterParent: [],
            fetchXML_filter: [],
        };
    },
    async created() {
        this.crmService = new CrmService();
        console.log("created");
    },
    computed: {
        entityId() {
            return this.$route.params.id;
        },
        profileData() {
            return this.$store.getters.getProfile;
        },
        getFetchXmlString() {
            let text = '';
            if (this.fetchXML_filter.length > 0) {
                text = '<filter type="and">';
                text = text + " " + this.fetchXML_filter.join(' ');
                text = text + " </filter>";
            }

            return text;
        }
    },
    methods: {
        async OnSearch() {
            debugger;
            this.CrmDataTable_options.filterFetchXml = this.getFetchXmlString;
            this.CrmDataTable_key = this.CrmDataTable_key+1;

            /*
            try {
                const response = await this.crmService.GetAdvancedFindSearch(this.mainData);
                debugger;
                if (response) {
                    if (response.hata == true) {
                        this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                    }else {
                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
            }
            */
        },
        addRow(){
            debugger;
            this.mainData.filters.push({
                attributeName: "",
                attributeOperator: "",
                attributeValue: "",
                attributeType: "",
            });
        },
        removeRow(event){
            debugger;
            console.log(event);
            this.mainData.filters.splice(event, 1);

            this.filterParent = [];
            this.mainData.filters.forEach(filter => {

                let new_operator = '';
                let new_value = '';
                switch (filter.attributeOperator) {
                    case '%like%':
                        new_operator = 'like';
                        new_value = '%' + filter.attributeValue + '%';
                        break;
                    case 'like%':
                        new_operator = 'like';
                        new_value = filter.attributeValue + '%';
                        break;
                    case '%like':
                        new_operator = 'like';
                        new_value = '%' + filter.attributeValue;
                        break;
                    default:
                        new_operator = filter.attributeOperator
                        new_value = filter.attributeValue;
                        break;
                }

                this.filterParent.push( { 'name': filter.attributeName, 'filterOperator': new_operator, 'value': new_value } );
            });

            this.convertToFetchXml();
        },
        selectAttribute(event){
            debugger;
            console.log(event);
            this.mainData.filters[event.index] = event;

            this.filterParent = [];
            this.mainData.filters.forEach(filter => {

                let new_operator = '';
                let new_value = '';
                switch (filter.attributeOperator) {
                    case '%like%':
                        new_operator = 'like';
                        new_value = '%' + filter.attributeValue + '%';
                        break;
                    case 'like%':
                        new_operator = 'like';
                        new_value = filter.attributeValue + '%';
                        break;
                    case '%like':
                        new_operator = 'like';
                        new_value = '%' + filter.attributeValue;
                        break;
                    default:
                        new_operator = filter.attributeOperator
                        new_value = filter.attributeValue;
                        break;
                }

                const item = { 'name': filter.attributeName, 'filterOperator': new_operator, 'value': new_value };
                
                this.filterParent.push( item );
                this.convertToFetchXml();
            });
        },
        changeEntityList(event){
            debugger;
            this.mainData.objectTypeCode = this.entityList.find(el => el.attributeValue === event.value).objectTypeCode;
            this.mainData.filters = [],
            this.getAttributes(event.value);
        },
        async getAttributes(entityName) {
            debugger;
            let loader = this.$loading.show({
                container: this.$refs.smsContainer
            });

            try {
                const response = await this.crmService.GetAttributeListByEntityName(entityName);
                debugger;
                if (response) {
                    if (response.hata == true) {
                        this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                    }else {
                        this.attributeList = response.attributeList;
                        this.addRow();
                        debugger;
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
            }
            finally {
				loader.hide();
			}
        },
        convertToFetchXml(){
            this.fetchXML_filter = [];

            debugger;
            if (this.filterParent.length > 0) {
                for (let index = 0; index < this.filterParent.length; index++) {
                    const fltr = this.filterParent[index];
                    let filterString = '';
                    debugger;

                    if (fltr.filterOperator == 'in') {
                        filterString += `<condition attribute="${fltr.name}" operator="${fltr.filterOperator}">`;

                        for (let in_index = 0; in_index < fltr.value.length; in_index++) {
                            filterString += `<value>${fltr.value[in_index]}</value>`;
                        }

                        filterString += "</condition>";
                    }
                    else {
                        filterString += `<condition attribute="${fltr.name}" operator="${fltr.filterOperator}" value="${fltr.value}"></condition>`;
                    }

                    this.fetchXML_filter.push(filterString);
                }
            }
        }
    },
    components: { AdvancedFindAttribute }
}
</script>

<style lang="scss" scoped>

</style>
