<template>
    <div class="grid p-fluid">
        <div class="col-4">
            <div class="field p-fluid">
                <Dropdown id="alanAdi" v-model="attributeData.attributeName" :options="attributeDataList" optionLabel="DisplayName" optionValue="LogicalName" placeholder="Alan Adı" @change="selectAttributeName($event)" :filter="true" :showClear="true" />
            </div>
        </div>
        <div class="col-3">
            <div class="field p-fluid">
                <Dropdown id="operator" v-model="attributeData.attributeOperator" :options="operatorDataList" optionLabel="label" optionValue="attributeValue" placeholder="Operatör" @change="selectAttributeOperator($event)" :filter="true" :showClear="true" />
            </div>
        </div>
        <div class="col-4">
            <div class="field p-fluid">
                <InputText id="deger" v-if="valueFieldType == 'string'" type="text" v-model="attributeData.attributeValue" placeholder="Değer" @change="selectAttributeValue($event)" />
                <Calendar showButtonBar id="deger" v-if="valueFieldType == 'date'" v-model="attributeData.attributeValue" @date-select="selectAttributeValueDateTime($event)" dateFormat="yyyy-mm-dd" autocomplete="off" :showIcon="true" />
                <InputNumber id="deger" v-if="valueFieldType == 'number'" v-model="attributeData.attributeValue" @input="selectAttributeValueNumber($event)" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" />
                
                
                <Button id="deger" v-if="selectedDataType == 'Lookup'" type="button" icon="pi pi-search" label="Ekle" @click="toggle_entityLookupAdd" aria-haspopup="true" aria-controls="overlay_panel" />
                
                

                <MultiSelect id="deger" v-if="valueFieldType == 'multiselect'" v-model="selectedMultiSelect" :options="multiSelectData" optionLabel="Value" placeholder="Seçiniz" :filter="true" class="multiselect-custom" @change="selectAttributeValueMultiSelect($event)" >
                    <template #value="slotProps">
                        <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
                            <div>{{option.Value}}</div>
                        </div>
                        <template v-if="!slotProps.value || slotProps.value.length === 0">
                            Kaynak seçiniz
                        </template>
                    </template>
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.Value}}</div>
                        </div>
                    </template>
                </MultiSelect>
            </div>
        </div>
        <div class="col-1" style="text-align:right">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="removeRow()" />
        </div>
    </div>

    <OverlayPanel ref="overlayPanel_entity" appendTo="body">
        <EntityLookupPopup id="deger" v-model="attributeData.attributeValue" ref="entity_tezgah" 
            label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true"
            @itemSelected="tezgahSelected = $event" @itemCleared="tezgahSelected = null"
            :CrmDataTable_options="CrmDataTable_options">
        </EntityLookupPopup>
    </OverlayPanel>
    
</template>

<script>
import CrmService from "../service/CrmService";

export default {
    props: {
        index: {
            type: Number,
        },
        rowData: {
            type: Object,
        },
        attributeDataList: {
            type: Object,
        },
        entityName: {
            type: String
        },
        objectTypeCode: {
            type: Number
        }
    },
    created() {
		this.crmService = new CrmService();
        if (this.rowData) {
            this.attributeData = this.rowData;
        }
	},
    data: function () {
        return {
            attributeData: {
                attributeName: "",
                attributeOperator: "",
                attributeValue: "",
            },
            operatorDataList: [],
            selectedEntity: {},
            selectedOperator: null,
            selectedDataType: null,
            selectedMultiSelect: [],
            isVisibleValue: false,
            valueFieldType: null,
            CrmDataTable_options: {
				searchAttributes: ["bm_serino", "bm_urunidname"],
            },
            multiSelectData: [],


        }
    },
    computed: {
    },
    methods: {
        removeRow() {
            debugger;
            this.$emit('removeRow', this.index);
		},
        selectAttributeName(event) {
            debugger;
            let seciliAttribute = this.attributeDataList.find(e => e.LogicalName == event.value);
            this.selectedDataType = seciliAttribute.AttributeType;

            switch (seciliAttribute.AttributeType) {
                case "String":
                    this.operatorDataList = [
                        { label: "Eşittir", attributeValue: "eq", valueFieldType: 'string' },
                        { label: "Eşit Değildir", attributeValue: "ne", valueFieldType: 'string' },
                        { label: "İçerir", attributeValue: "%like%", valueFieldType: 'string' },
                        { label: "İçermez", attributeValue: "%not-like%", valueFieldType: 'string' },
                        { label: "İle Başlar", attributeValue: "like%", valueFieldType: 'string' },
                        { label: "İle Başlamaz", attributeValue: "not-like%", valueFieldType: 'string' },
                        { label: "İle Biter", attributeValue: "%like", valueFieldType: 'string' },
                        { label: "İle Bitmez", attributeValue: "%not-like", valueFieldType: 'string' },
                        { label: "Veri İçerir", attributeValue: "not-null", valueFieldType: null },
                        { label: "Veri İçermez", attributeValue: "null", valueFieldType: null },
                    ];
                    break;
                case "Money":
                case "Double":
                case "Decimal":
                case "Integer":
                case "BigInt":
                    this.operatorDataList = [
                        { label: "Eşittir", attributeValue: "eq", valueFieldType: 'number' },
                        { label: "Eşit Değildir", attributeValue: "ne", valueFieldType: 'number' },
                        { label: "Büyüktür", attributeValue: "gt", valueFieldType: 'number' },
                        { label: "Büyük ve Eşittir", attributeValue: "ge", valueFieldType: 'number' },
                        { label: "Küçüktür", attributeValue: "lt", valueFieldType: 'number' },
                        { label: "Küçük Eşittir", attributeValue: "le", valueFieldType: 'number' },
                        { label: "Veri İçerir", attributeValue: "not-null", valueFieldType: null },
                        { label: "Veri İçermez", attributeValue: "null", valueFieldType: null },
                    ];
                    break;
                case "Memo":
                    this.operatorDataList = [
                        { label: "İçerir", attributeValue: "%like%", valueFieldType: 'string' },
                        { label: "İçermez", attributeValue: "%not-like%", valueFieldType: 'string' },
                        { label: "İle Başlar", attributeValue: "like%", valueFieldType: 'string' },
                        { label: "İle Başlamaz", attributeValue: "not-like%", valueFieldType: 'string' },
                        { label: "İle Biter", attributeValue: "%like", valueFieldType: 'string' },
                        { label: "İle Bitmez", attributeValue: "%not-like", valueFieldType: 'string' },
                        { label: "Veri İçerir", attributeValue: "not-null", valueFieldType: null },
                        { label: "Veri İçermez", attributeValue: "null", valueFieldType: null },
                    ];
                    break;
                case "Picklist":
                case "Boolean":
                case "Status":
                case "Lookup":
                case "Owner":
                    this.operatorDataList = [
                        { label: "Eşittir", attributeValue: "in", valueFieldType: 'multiselect' },
                        { label: "Eşit Değildir", attributeValue: "not-in", valueFieldType: 'multiselect' },
                        { label: "İçerir", attributeValue: "%like%", valueFieldType: 'string' },
                        { label: "İçermez", attributeValue: "%not-like%", valueFieldType: 'string' },
                        { label: "İle Başlar", attributeValue: "like%", valueFieldType: 'string' },
                        { label: "İle Başlamaz", attributeValue: "not-like%", valueFieldType: 'string' },
                        { label: "İle Biter", attributeValue: "%like", valueFieldType: 'string' },
                        { label: "İle Bitmez", attributeValue: "%not-like", valueFieldType: 'string' },
                        { label: "Veri İçerir", attributeValue: "not-null", valueFieldType: null },
                        { label: "Veri İçermez", attributeValue: "null", valueFieldType: null },
                    ];
                    break;
                case "DateTime":
                    this.operatorDataList = [
                        { label: "Tarihinde", attributeValue: "on", valueFieldType: 'date' },
                        { label: "Tarihinde veya Daha Sonra", attributeValue: "on-or-after", valueFieldType: 'date' },
                        { label: "Tarihinde veya Daha Önce", attributeValue: "on-or-before", valueFieldType: 'date' },
                        { label: "Dün", attributeValue: "yesterday", valueFieldType: null },
                        { label: "Bugün", attributeValue: "today", valueFieldType: null },
                        { label: "Yarın", attributeValue: "tomorrow", valueFieldType: null },
                        { label: "Sonraki 7 gün", attributeValue: "next-seven-days", valueFieldType: null },
                        { label: "Önceki 7 Gün", attributeValue: "last-seven-days", valueFieldType: null },
                        { label: "Gelecek Hafta", attributeValue: "next-week", valueFieldType: null },
                        { label: "Geçen Hafta", attributeValue: "last-week", valueFieldType: null },
                        { label: "Bu Hafta", attributeValue: "this-week", valueFieldType: null },
                        { label: "Gelecek Ay", attributeValue: "next-month", valueFieldType: null },
                        { label: "Geçen Ay", attributeValue: "last-month", valueFieldType: null },
                        { label: "Bu Ay", attributeValue: "this-month", valueFieldType: null },
                        { label: "Gelecek Yıl", attributeValue: "next-year", valueFieldType: null },
                        { label: "Geçen Yıl", attributeValue: "last-year", valueFieldType: null },
                        { label: "Bu Yıl", attributeValue: "this-year", valueFieldType: null },
                        { label: "Son X Saat", attributeValue: "last-x-hours", valueFieldType: 'number' },
                        { label: "Sonraki X Saat", attributeValue: "next-x-hours", valueFieldType: 'number' },
                        { label: "Son X Gün", attributeValue: "last-x-days", valueFieldType: 'number' },
                        { label: "Sonraki X Gün", attributeValue: "next-x-days", valueFieldType: 'number' },
                        { label: "Son X Hafta", attributeValue: "last-x-weeks", valueFieldType: 'number' },
                        { label: "Sonraki X Hafta", attributeValue: "next-x-weeks", valueFieldType: 'number' },
                        { label: "Son X Ay", attributeValue: "last-x-months", valueFieldType: 'number' },
                        { label: "Sonraki X Ay", attributeValue: "next-x-months", valueFieldType: 'number' },
                        { label: "Son X Yıl", attributeValue: "last-x-years", valueFieldType: 'number' },
                        { label: "Sonraki X Yıl", attributeValue: "next-x-years", valueFieldType: 'number' },
                        { label: "X Aydan Daha Eski", attributeValue: "olderthan-x-months", valueFieldType: 'number' },
                        { label: "Veri İçerir", attributeValue: "not-null", valueFieldType: null },
                        { label: "Veri İçermez", attributeValue: "null", valueFieldType: null },
                    ];
                    break;
                default:
                    break;
            }

            debugger;
            this.attributeData["index"] = this.index;
            this.$emit('selectAttribute', this.attributeData);
		},
        selectAttributeOperator(event) {
            debugger;
            console.log(event);
            this.selectedOperator = event;
            let seciliOperator = this.operatorDataList.find(e => e.attributeValue == event.value);
            this.valueFieldType = seciliOperator.valueFieldType;

            if (event.value == 'in') {
                this.multiSelectData = this.$store.getters.getStringMapByEntityId(this.objectTypeCode, this.attributeData.attributeName);
            }

            this.attributeData["index"] = this.index;
            this.$emit('selectAttribute', this.attributeData);
        },
        selectAttributeValueDateTime(event) {
            debugger;
            console.log(event);
            this.attributeData.attributeValue.setTime(this.attributeData.attributeValue.getTime() + (3*60*60*1000));
            this.attributeData.attributeValue = this.attributeData.attributeValue.toISOString().split('T')[0];

            this.attributeData["index"] = this.index;
            this.$emit('selectAttribute', this.attributeData);
        },
        selectAttributeValueNumber(event) {
            debugger;
            console.log(event);
            this.attributeData.attributeValue = event.value

            this.attributeData["index"] = this.index;
            this.$emit('selectAttribute', this.attributeData);
        },
        selectAttributeValueMultiSelect(event) {
            debugger;
            console.log(event);
            const attributeValues = event.value.map(function(item) {
                return item.AttributeValue;
            });

            this.attributeData.attributeValue = attributeValues;

            this.attributeData["index"] = this.index;
            this.$emit('selectAttribute', this.attributeData);
        },
        selectAttributeValue(event) {
            debugger;
            console.log(event);
            this.attributeData["index"] = this.index;
            this.$emit('selectAttribute', this.attributeData);
        },
        toggle_entityLookupAdd(event) {
            this.$refs.overlayPanel_entity.toggle(event);
        },
	}
}
</script>

<style lang="scss" scoped>
.country-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

::v-deep(.multiselect-custom) {
    .p-multiselect-label:not(.p-placeholder) {
        padding-top: .50rem;
        padding-bottom: .50rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }
}

@media screen and (max-width: 640px) {
    .p-multiselect {
        width: 100%;
    }
}
</style>